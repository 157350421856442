import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Inputs come in two different colors. The default input color is `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-background`}</inlineCode>{` and `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` page backgrounds. The `}<inlineCode parentName="p">{`--light`}</inlineCode>{` version input color is `}<inlineCode parentName="p">{`$field-02`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-02`}</inlineCode>{` page backgrounds.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input::placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form__helper-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input--light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-04`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "44.565217391304344%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABDklEQVQoz41RO26DQBRkFyWVXeYSbtJwACtS7pCCJpavwGmAgkPkCHaKHAAJLUWE+P8/goKCzK6yUZLCMNJoih3NvnlPUf5hWZYfBUlVVbQoCtq2Le37nnZdx1V4pK5Cht7CMAzbwjzPE8oYu3Nd9znP86eyLI+Y7IgQyf3mUFQjXOd53qPqJY7jzyRJGEJZ0zR+XdcMfOQeKF0N1HVdqKZpxDCMHd8jX4HjOMQ0TWLbNrEsS3ik3gR+lXvchWH45vv+R5ZlVxzgiunfwQt4+G6zPiGuKnQcx/s0TV+CIDij8gmVT1gB5yv4wD1Qsukw0zStevDBtivjV6E4gIKqahRFKiZVEaDiTfKP9ze+AEgG01daDwfBAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Default and user input states for text input in both field colors",
          "title": "Default and user input states for text input in both field colors",
          "src": "/static/15613f2c772dbe19ea7c7ebb73c3083a/fb070/text-input-style-1.png",
          "srcSet": ["/static/15613f2c772dbe19ea7c7ebb73c3083a/d6747/text-input-style-1.png 288w", "/static/15613f2c772dbe19ea7c7ebb73c3083a/09548/text-input-style-1.png 576w", "/static/15613f2c772dbe19ea7c7ebb73c3083a/fb070/text-input-style-1.png 1152w", "/static/15613f2c772dbe19ea7c7ebb73c3083a/c3e47/text-input-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Examples of default and user-input states for text input in both $field-02
  (left) and $field-01 (right)
    </Caption>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input[data-invalid]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`warning--filled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Text input labels and placeholder text should be set in sentence case, with only the first word in a phrase and any proper nouns capitalized. Text input labels should be three words or less.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-long-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form__helper-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-input:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.55978260869565%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABMUlEQVQoz2O4qJ7KAAIg+phHF5hdb/eGYefeR4xvP7xl/PjhI+OHjx8Zv3z+zPj20yfGo2/egNUA+QzrXr5kwAAgg85pJ4PZN/m9GFabLGQgBN4ADcMLritkoPCT8/4zLp5/XP3Jiydyf//8lfz69avUt69fJT98+SJ9/O1bfpAaoBjD+lc4XAilGS/oZ/M8MMvlrGJQZD9+7/GWF69f/XgFBG/fvn3z+dOnl28/fvx17O2bHpB6IJ8Jr4GXgPRJ/Uym/3I5jInawcz1e3ZrXr11U/3WjRsqt+/cUX3++InK2du3tYr371UBqb9y+zZDxI5t2L0MM5RY8P/PH9ySyLF8XTebYYtbEcN/kKb//xkePHrIeO/ePcZHjx4xPn78mPHdmzeMIHGwoV++MsDYyAAAAbuoUkYy+9cAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for text input",
          "title": "Structure and spacing measurements for text input",
          "src": "/static/39d9a51dec05ffd7de8f216268fa48e6/fb070/text-input-style-2.png",
          "srcSet": ["/static/39d9a51dec05ffd7de8f216268fa48e6/d6747/text-input-style-2.png 288w", "/static/39d9a51dec05ffd7de8f216268fa48e6/09548/text-input-style-2.png 576w", "/static/39d9a51dec05ffd7de8f216268fa48e6/fb070/text-input-style-2.png 1152w", "/static/39d9a51dec05ffd7de8f216268fa48e6/c3e47/text-input-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing measurements for text input | px / rem</Caption>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "111.68478260869566%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsSAAALEgHS3X78AAADTElEQVQ4y6VV30/aUBSmiDD5YTTux3/iv2BMXOaioj4Zo/HF7MnIy/aGusTFZXNKNCQuSpYtcxLjMqPOmWxuqDhRUavQQqlQoGAFCsKm2di917WjwQ23neRwzr09/fjO+e4FmQxYVVWV7H+MIIhfi83NTVlHRwfK+/r6tCaTqTQcDhUTHkoZ5k6ukFRAnesuT6DkwO1RkiShJAgSRFIB3wXxHBgCJpNJBLi3t4c5txyYN8hjkwVYOXA/5iVdGADBBJYiU7lcLhbeG7GhaJ4L6j5uMQYikOp2+VNd0Akm1b1Npe5MLGdKYc3Qh2x+y9ns+WZdXZ2EweTaaYWT4iepUPKtN5iag+4DOU6nrM9t6RuwZnE7iQlgEob19fUo1tTUKKqrq4tv3rot/xtR4PxEw3FcFKW/v187MPCw3GKxaNgopwTsS7Lfvqiz2e8lMY7VRMMBTSjIaFyEV4VEIUkVYKUQGCJglmUFdpJvBRThsIt+x2p1N4zRnj2RHYwi09raWhQrKytl901TSLVXq5nShRWy175DDziJ6IN9OmEEohiBKHcty5kyWGNdP8tvWTC9Xi8RacGVLbfjkQkXzU3TkcwUEMUKRJnBD1PPXtjS12HN/FYaywOECwGssbFRqW9oKLqMEEtOXsJObBl+dHZ2os3BwUG11WrVRCIRpY+iVbF4XE16PVraT2uZUFDLRlltwOXWEm4SiUKei1IsEUWwpqamvLP5J+NtqxffZYja3NyM8tbWVtmGdRrN5Wh2vsy7uvbYt+00+TYcw1GXezhB+UaOFt4NRGfeVKCapffYhSoLYLns+KnpcnZlzRywf37J7OyOcV7KnDj0P+U+rTyJvp5FgIn5xXyGcNHS0oJyg8Eg7+7qKlrfx7FCLR9ubmEHFCWyEq8e3Ghvb0ebvb29OqPReG3dblcFQiFl8PSrmj47BaL4dQx9qAsxjI5xu3VeglCBW6IkYfx5U/JaFkD/xfLOYVtbG8p7enpkDocDtZtIJMo5jhs5Pj42x2KxUZ7nR8HvphnER8DRDOPxuEQUySwFAy8hwHQ6XQZAhwDYOPAxADIGno2DOAzi1dzaggbACp9Dnr/cPIS/g5OTE9iSArBDDgAU4JkQJbW59gO3eFjtSriTMAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Active, help, error or disabled states for text input",
          "title": "Active, help, error or disabled states for text input",
          "src": "/static/cf24cb64781c9fd7335c9dad768db8c4/fb070/text-input-style-3.png",
          "srcSet": ["/static/cf24cb64781c9fd7335c9dad768db8c4/d6747/text-input-style-3.png 288w", "/static/cf24cb64781c9fd7335c9dad768db8c4/09548/text-input-style-3.png 576w", "/static/cf24cb64781c9fd7335c9dad768db8c4/fb070/text-input-style-3.png 1152w", "/static/cf24cb64781c9fd7335c9dad768db8c4/c3e47/text-input-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Examples of active, help, error and disabled text input states
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      